import React from "react";

import { useMounted } from "@/hooks/loading";
import type { Iframe as IframeSlotProps } from "@/types/contentful-api";

import { StyledIframe, StyledIframeWrapper } from "./styled";

export const Iframe = ({ iframeUrl, label }: IframeSlotProps) => {
	const mounted = useMounted();
	return (
		<StyledIframeWrapper>
			{mounted && (
				<StyledIframe
					allowFullScreen
					src={iframeUrl}
					title={label}
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					frameBorder="0"
					loading="lazy"
				/>
			)}
		</StyledIframeWrapper>
	);
};
