import { css } from "@emotion/react";
import styled from "@emotion/styled";

import type { PropsWithTheme } from "@/theme";

export const StyledIframeWrapper = styled.div<PropsWithTheme>`
	position: relative;
	padding-top: 100%;
	overflow: hidden;
	${({ theme: { mq } }) => css`
		@media ${mq.m} {
			padding-top: 56.25%;
		}
	`}
`;

export const StyledIframe = styled.iframe`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;
`;

export const StyledSpotifyIframe = styled.iframe<PropsWithTheme>`
	border-radius: 12px;
	height: 152px;
	width: 100%;
	${({ theme: { mq } }) => css`
		@media ${mq.l} {
			display: block;
			margin: 0 auto;
			width: 75%;
		}
	`}
`;
